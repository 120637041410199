/* ListAndAdd.css */

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .add-button {
    background: #4caf50;
    color: #fff;
    border: none;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .cancel-button {
    background: #f44336;
    color: #fff;
    border: none;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  .input{
    margin-top: 10px;
  }
  .td{
    color: black;
  }