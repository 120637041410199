.progress-round-chart circle:last-child {
    animation: progress-fill 2s ease-in-out forwards;
}

@keyframes progress-fill {
    from {
        stroke-dashoffset: 100%;
        /* Start with hidden progress */
    }

    to {
        stroke-dashoffset: 0;
        /* Animate to full progress */
    }
}

.doughnut-chat-master {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
}

.doughnut-chat-master label {
    position: absolute;
    font-size: 17px;
    color: #18d118;
    font-weight: 800;
    margin-top: 45px;
}