/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

li {
  list-style: none;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


/* custom CSS */

:root {
  /* primary colors */
  --Marine-blue: 213 96% 18%;
  --purpleish-blue: 243 100% 62%;
  --pastel-blue: 228 100% 84%;
  --light-blue: 206 94% 87%;
  --strawberry-red: 354 84% 57%;

  /* neutral colors */
  --cool-gray: 231 11% 63%;
  --Light-gray: 229 24% 87%;
  --magnolia: 217 100% 97%;
  --alabaster: 231 100% 99%;
  --white: 0 0% 100%;

  /* font weight */
  --fwL: 400;
  --fwM: 500;
  --fwB: 700;
}