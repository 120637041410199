/* Font Inter  */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
p,
body {
    font-family: 'Inter', sans-serif;
}

/*========== Button Start ==========*/
.header-btn {
    display: inline-block;
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #027a4f;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
    background-color: #027a4f;
}

.btn-1 {
    display: inline-block;
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #027a4f;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
}

.btn-1:hover {
    background-color: #027a4f;
}

.tabbed-table-mob .nav-link.active,
.tabbed-table .nav-link.active,
.primary-btn {
    display: inline-block;
    padding: 10px;
    border-radius: 8px;
    background-color: #027a4f !important;
    /* background-color:  #027a4f !important; */
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #027a4f !important;
    ;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
    width: max-content;
    cursor: pointer;
}

.primary-btn:hover {
    background-color: #027a4f !important;
}

.tabbed-table-mob .nav-link,
.tabbed-table .nav-link,
.secondary-btn {
    background-color: #f9f9f9 !important;
    color: #333;
    display: inline-block;
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #f9f9f9 !important;
    text-decoration: none;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
}

.tabbed-table .nav-link:hover,
.secondary-btn:hover {
    background-color: #027a4f !important;
    border: 2px solid #027a4f !important;
    color: #fff;
}

.third-btn {
    background-color: #999 !important;
    color: #111;
    display: inline-block;
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #999 !important;
    text-decoration: none;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
    cursor: pointer;
}

.third-btn:hover {
    background-color: #027a4f !important;
    border: 2px solid #027a4f !important;
    color: #fff;
}

.btn-sm {
    padding: 5px;
    border-radius: 8px;
    text-decoration: none;
    font-size: 14px;
    line-height: 26px;
}

.dashboard-table-tab .nav-link.active {
    display: inline-block;
    padding: 10px;
    border-radius: 8px;
    background-color: #027a4f !important;
    /* background-color:  #027a4f !important; */
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #027a4f !important;
    ;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
    width: max-content;
    cursor: pointer;
}

.dashboard-table-tab .nav-link {
    display: inline-block;
    padding: 10px;
    border-radius: 8px;
    background-color: transparent !important;
    /* background-color:  #027a4f !important; */
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #027a4f !important;
    ;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
    width: max-content;
    cursor: pointer;
}

/*========== Button End ==========*/
/*============= Common Css Start =============*/
h1 {
    color: #fff !important;
    font-size: 58px !important;
    font-weight: 800 !important;
}

h1 div {
    color: #027a4f;
}

.tilte-header h2 {
    font-size: 34px;
    line-height: 44px;
    font-weight: 800;
    color: #fff;
}

.theme-primary-text,
.tilte-header h2 span {
    color: #027a4f;
}

h4 {
    font-size: 24px;
    color: #ffffff;
    line-height: 34px;
    letter-spacing: 0.5px;
    font-weight: 700;
}

.header-para {
    color: rgba(255, 255, 255, 0.7);
    font-size: 20px;
    font-weight: 500;
}

p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 17px;
    font-weight: 500;
}

.section-primary-bg {
    background: #212224;
}

.section-marquee-bg {
    height: 220px;
    background: linear-gradient(0deg, rgb(33, 34, 36) 65%, rgba(33, 34, 36, 0.3) 100%);
    margin-top: -80px;
}

.section-padding {
    padding-top: 60px;
    padding-bottom: 60px;

}

.mt-23 {
    margin-top: 23px;
}

.fs-14 {
    font-size: 14px;
    line-height: 24px;
}

/* fdsf */
.anim-btn {
    font-size: 1.1em;
    padding: 20px 0;
    color: #fff;
    border-radius: 50px;
    margin: auto;
}

.anim-btn span {
    color: white;
    font-size: 0;
    padding: 10px 30px;
}

.anim-button {
    margin: 30px;
    background-color: #027a4f;
    padding: 17px;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.anim-button span {
    color: white;
    padding: 0px 30px;
}

.anim-btn:hover {
    padding: 20px 50px 20px 10px;
}

.anim-btn:hover span {
    font-size: 1.0em;
    text-decoration: none !important;
}

.anim-btn:hover .ico {
    margin-left: 0px;
    transform: rotate(360deg);
}

.anim-btn:hover .span {
    visibility: visible;
    text-decoration: none !important;
}

/* .ico {
    transition: all 0.5s;
    background-color: #4c4c4d !important;
    color: #E94751;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: absolute;
    margin-left: -50px;
    margin-top: -10px;
    font-size: 23px;
} */
.ico {
    transition: all 0.5s;
    background-color: #4c4c4d !important;
    color: #E94751;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font-size: 23px;
    padding: 4px 11px;
}

.ico svg {
    margin-top: -4px;
}

body {
    overflow: auto !important;
}

/*============= Common Css End =============*/
::marker {
    color: #dcdcdc !important;
}

.menu-bar .nav-link {
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.menu-bar .nav-link:hover {
    color: #027a4f;
}

.menu-bar .primary-btn {
    padding: 6px 10px;
}

.navbar-brand {
    padding: 0px !important;
}

.inner-page-bg-1 {
    background: linear-gradient(135deg, #333537 35.49%, #161719 77.91%);
}

.inner-page-bg {
    background-color: #212224;
}

.page-leftsidebar {
    background: linear-gradient(135deg, #333537 35.49%, #161719 77.91%);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 10px;
    box-shadow: none;
    border-radius: 10px;
    position: relative;
    width: auto;
    margin: 0 auto 0 auto;
    z-index: 999;
}

/* .page-leftsidebar ul li:nth-child(1) .ico {
    background-color: #027a4f !important;
} */


.page-leftsidebar ul .dropdown li:last-child {
    background-color: transparent !important;
    border-radius: 50px;
}

.page-leftsidebar ul .dropdown li a:hover {
    color: #027a4f;
}

.logout-icon {
    top: 17px;
    right: 15px;
}

.page-leftsidebar ul li {
    margin-bottom: 0px;
}

.page-leftsidebar .nav-link.active {
    background-color: #4c4c4d !important;
    border-radius: 10px !important;
}

.page-leftsidebar .nav-link:hover {
    background-color: #4c4c4d !important;
    border-radius: 10px !important;
}

.card-div {
    background: linear-gradient(135deg, #333537 35.49%, #161719 77.91%);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid #027a4f;
    padding: 10px;
    border-radius: 5px;

}

/* .dash-top {
    padding-top: 100px;
} */

/*=============  Header  Start =============*/


.header-bg {
    background-image: url("./images/landing-page/header_bg.png");
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-color: #cccccc;
    padding: 40px 0 100px 0;
    background-color: #212216;
}

.login-bg {
    background-image: url("./images/landing-page/header_bg.png");
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-color: #cccccc;
    padding: 50px 0 100px 0;
    background-color: #212224;
}

.header-element {
    margin: 0 auto;
}

.trans-bg {
    background: transparent;
}

/*=============  Header  Start =============*/
/*=============  Other-Logo  Start =============*/



.marquee {
    --gap: 0rem;
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    width: 100%;
}

.marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    gap: var(--gap);
    min-width: fit-content;
}

.marquee__content img {
    width: 85px;
}

.marquee__content .green-box {
    color: #176639;
    background-color: #2ecc71;
    font-weight: 500;
    border-radius: 3px;
    font-size: 12px;
    margin-left: 10px;
    padding: 2px 9px 2px 8px;
}

.marquee__content .red-box {
    color: #772838;
    background-color: #ee4f6f;
    font-weight: 500;
    border-radius: 3px;
    font-size: 12px;
    margin-left: 10px;
    padding: 2px 9px 2px 8px;
}


@keyframes scroll {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(calc(-100% - var(--gap)));
    }
}

/* Enable animation */
.enable-animation .marquee__content {
    animation: scroll 15s linear infinite;
}

/* Attempt to size parent based on content. Keep in mind that the parent width is equal to both content containers that stretch to fill the parent. */
.marquee--fit-content {
    max-width: fit-content;
}

/* A fit-content sizing fix: Absolute position the duplicate container. This will set the size of the parent wrapper to a single child container. Shout out to Olavi's article that had this solution 👏 @link: https://olavihaapala.fi/2021/02/23/modern-marquee.html  */
.marquee--pos-absolute .marquee__content:last-child {
    position: absolute;
    top: 0;
    left: 0;
}

/* Enable position absolute animation on the duplicate content (last-child) */
.enable-animation .marquee--pos-absolute .marquee__content:last-child {
    animation-name: scroll-abs;
}

@keyframes scroll-abs {
    from {
        transform: translateX(calc(100% + var(--gap)));
    }

    to {
        transform: translateX(0);
    }
}

/* Other page demo styles */
.marquee__content>* {
    flex: 0 0 auto;
    color: #1f1f1f;
    font-size: 26px;
    font-weight: 600;
    margin: 2px;
    padding: 1rem 2rem;
    border-radius: 0.25rem;
}

/*=============  Other-Logo  End =============*/
/*=============  Services Start =============*/
.service-img-hover .ser-ellispe-1,
.sevice-card:hover .ser-ellispe {
    display: none;
}

.service-img-hover img {
    width: 200px !important;
    margin: 0 auto;
}

.service-img-hover p {
    padding-bottom: 0;
}

.sevice-card:hover .ser-ellispe-1 {
    display: block !important;
}

.sevice-card {
    background-color: #212224;
    color: #fff;
    overflow: hidden;
    cursor: pointer;
}

.sevice-card img {
    position: relative;
    right: -85px;
    /* margin-top: -87px;
        margin-bottom: -60px; */
}

.services-card-body {
    padding: 15px;
}

.card-effect {
    background-color: rgba(33, 34, 36, 0.06);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border: 0.5px solid rgba(128, 128, 128, 0.33) !important;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 8px;
    box-shadow: 20px 20px 22px rgba(0, 0, 0, 0.2);
    padding: 10px
}

.circle-2 {
    position: absolute;
    border-radius: 50%;
    background: #027a4f;
    height: 100px;
    width: 100px;
    top: -50px;
    right: -50px;
    transition: all 1s;
}

.circle-1 {
    position: absolute;
    border-radius: 50%;
    background: #027a4f;
    height: 100px;
    width: 100px;
    bottom: -50px;
    right: -50px;
    transition: all 1s;
}

.circle-round-1 {
    position: absolute;
    border-radius: 50%;
    background: #027a4f;
    height: 50px;
    width: 50px;
    top: -5px;
    right: -24px;
    transition: all 1s;
}

.circle-round-2 {
    position: absolute;
    border-radius: 50%;
    background: #027a4f;
    height: 50px;
    width: 50px;
    top: -5px;
    left: -24px;
    transition: all 1s;

}

.dashboard-accout-grp span {
    font-size: 14px;
}

.dashboard-accout-grp span:nth-child(1) {
    color: #666;
}

/*=============  Services End =============*/
/*=============  Table Section Start =============*/
.tabbed-table {
    position: absolute;
    top: 0px;
    right: 45%;
}

.primary-table {
    margin-top: 50px;
}

th,
.primary-table th {
    font-size: 18px;
    color: #027a4f;
    border: 0;
    background-color: transparent !important;
    white-space: nowrap;
    vertical-align: middle;
}

.primary-table .table-heading th {
    background: linear-gradient(135deg, #333537 35.49%, #161719 77.91%);
    color: #fff;
    border-radius: 5px;
    text-align: center;
}

.primary-table .table-heading th:nth-child(1) {
    background: transparent;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.primary-table tbody tr:nth-child(odd) {
    background-color: #282828;
    border-radius: 5px;
}

.primary-table tbody tr:nth-child(even) {
    background-color: #484848;
    border-radius: 5px;
}

.primary-table tbody tr:last-child {
    background-color: transparent;
}

.primary-table a {
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    font-size: 14px;
    vertical-align: middle;
}

td,
.primary-table td {
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    border-bottom: 0;
    background-color: transparent !important;
    vertical-align: middle;
}

.primary-table .card-body {
    font-size: 16px;
    line-height: 24px;
    color: #999;
}

.mob-mode-table .accordion-button {
    background: linear-gradient(135deg, #333537 35.49%, #161719 77.91%);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 10px;
    border-radius: 5px;
    color: #fff !important;
}

/*=============  Table Section End =============*/
.slider-bg {
    background-color: #027a4f;
    border-radius: 10px;
}

.tier-grp .tier-btn {
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
}

.tier-grp .tier-btn p {
    color: #373838;
}

.tier-grp {
    padding: 25px;
}

.tier-grp h2 {
    width: 70%;
}

.slider-2 .process-bg {
    background-color: #373838;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
}

.slider-2 {
    padding: 20px;
}

.slider-2 .process-bg .number {
    color: #fff !important;
}

.slider-2 .owl-nav {
    margin-top: 15px;
}

.slider-2 .owl-nav .owl-next span,
.slider-2 .owl-nav .owl-prev span {
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 700;
    border-radius: 10px;
    margin: 5px;
}

.profit-head {
    font-size: 70px;
}

.profit-grp {
    border-left: 0.3px solid rgba(0, 0, 0, 0.3);
    padding-top: 250px;

}

.profit-grp p {
    color: #373838;
}

.profit-grp p:nth-child(2) {
    border-bottom: 0.3px solid rgba(0, 0, 0, 0.3);
}

/* steps */
.steps-bg {
    background-image: url("./images/landing-page/step-bg.png");
    background-color: transparent;
    border: 1px solid #d8dfe2;
    border-radius: 10px;
    background-position: 0 0;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 40px;
}

.steps-bg h4 {
    color: #027a4f;
    background-color: #fff;
    display: inline-block;
    text-align: center;
    width: auto;
    display: table;
    margin: -53px auto 0 auto;
    padding: 10px;
}

.first p:nth-child(1) {
    color: #373838;
    font-size: 30px;
    background-color: #fff;
    border-radius: 50px;
    padding: 10px 25px;
    display: table;
    margin: 0 auto;

}

.in-liquid-category {
    width: 59px;
    position: relative;
}

.in-liquid-category::before {
    width: 1px;
    height: 98%;
    background-color: #027a4f;
    content: "";
    position: absolute;
    top: 0;
    left: 7px;
}

.in-liquid-category .uk-text-small {
    font-size: 12px;
    font-weight: 600;
}

.in-liquid-category .uk-text-uppercase {
    letter-spacing: 1px;
    color: #027a4f;
}

.in-liquid-category p {
    width: 150px;
    text-align: right;
    transform: rotate(-90deg);
    margin-top: 64px;
    margin-left: -67px;
}

.readmore {
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    -webkit-appearance: none;
    border-radius: 0;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    font-size: 11.5px;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all ease 400ms;
    color: #999;
}

/* faq */
.faq-grp .accordion-button.collapsed,
.faq-grp .accordion-button:not(.collapsed) {
    background-color: #4f4f4f !important;
    color: #fff !important;
    border-radius: 10px 10px 0 0;
    border: 0 !important;
}

.faq-grp .accordion-button.collapsed {
    border-radius: 10px;

}

.faq-grp .accordion-button.collapsed:focus-visible {
    border: 0 !important;
}

.faq-grp .accordion-button:not(.collapsed):focus,
.faq-grp .accordion-button.collapsed:focus {
    box-shadow: none !important;
}

.faq-grp .accordion-item {
    border: 0 !important;
    border-radius: 10px !important;
    margin-bottom: 30px !important;
}

.faq-grp .accordion-item:last-child {
    margin-bottom: 0 !important;
}

.accordion-button:not(.collapsed)::after {
    background-color: #fff;
    padding: 10px;
    border-radius: 14px;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
}

.footer-list li {
    margin-bottom: 15px;
    ;
}

.footer-list li a:hover {
    color: #fff !important;
}

.copyright:after {
    position: absolute;
    top: calc(50% - (calc(0.2px + 0.05em) / 2));
    width: 2000px;
    border-bottom: calc(0.2px + 0.05em) solid #e5e5e5;
    color: rgba(255, 255, 255, 0.5);
}

/*============ login Start ==============*/
.login-element {
    width: 60%;
}

.register-link a {
    font-weight: 800;
    color: #027a4f;
    letter-spacing: 0.8px;
    margin-left: 8px;
}

.form-div select,
.form-div #dropdown,
.form-div input {
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2x);
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
}

.form-div select option {
    margin: 40px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.form-div label {
    color: rgba(255, 255, 255, 0.50);
    margin-bottom: 5px;
}

.form-div label span {
    color: rgb(255, 50, 8);
}

.form-div input:focus {
    box-shadow: none;
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border: 0;
    color: #fff;
}

::placeholder {
    color: #999 !important;
}

.coninue-with {
    position: relative;
    content: '';
    border-top: 1px solid rgba(255, 255, 255, 0.18);
    margin-top: 40px;

}

/* account-details */
.account-bg {
    padding: 10px;
    background-color: rgb(255, 50, 8);
    display: block;
    border-radius: 10px;
}

.account-bg:hover {
    background-color: rgb(255, 50, 8, 0.5);
}

.register-link a {
    font-weight: 800;
    color: #027a4f !important;
    letter-spacing: 0.8px;
    margin-left: 8px;
    text-decoration: underline !important;
}

/*custom font*/
.step-container {
    position: relative;
    text-align: center;
    transform: translateY(-43%);
}

.step-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid rgb(255, 50, 8, 0.5);
    line-height: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;
    /* Added cursor pointer */
}

.step-line {
    position: absolute;
    top: 16px;
    left: 50px;
    width: calc(100% - 100px);
    height: 2px;
    background-color: #007bff;
    z-index: -1;
}

#multi-step-form {
    overflow-x: hidden;
}

.progress {
    width: 100%;
    height: 30px;
    font-size: 16px;
    margin: 0 auto;
}

.red-indicate {
    --bs-progress-bar-bg: #e14646 !important;
    --bs-progress-bg: #22c962 !important;
}


.green-indicate {
    --bs-progress-bar-bg: #22c962 !important;
    --bs-progress-bg: #e14646 !important;
}

.modal-content {
    background-color: #212224 !important;
}


/* breadcrumb */
.breadcrumb-item+.breadcrumb-item::before {
    color: #999 !important;
}

.dash-btn {
    box-shadow: none;
    background: rgb(195 84 84 / 51%);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid #ff0000;
    border: 0;
    color: #f9f9f9;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
}

.dash-btn-1 {
    box-shadow: none;
    background: rgba(76, 174, 106, 0.51);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid #32d732cb;
    border: 0;
    color: #f9f9f9;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
}

.dash-border {
    border: 1px solid rgba(255, 255, 255, 0.2);
}

/* skill bar */
.skill-main {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.skill-main .skill-wrrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skill-main .skill-wrrap .skill-name {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
}

.skill-main .skill-wrrap .skill-bar {
    height: 8px;
    background-color: #4c4c4d;
    border-radius: 8px;
}

.skill-main .skill-wrrap .skill-per {
    height: 20px;
    background: #23576f;
    border-radius: 8px;
    width: 0;
    transition: 1s linear;
    position: relative;
}

.skill-main .skill-wrrap .skill-per:before {
    content: attr(per);
    position: absolute;
    padding: 4px 6px;
    background-color: #027a4f;
    color: #fff;
    font-size: 11px;
    border-radius: 4px;
    top: -35px;
    right: 0;
    transform: translateX(50%);
}

/* table */
.dashbord-table .table th {
    font-size: 17px;
    color: #027a4f;
    border: 0;
    background-color: transparent !important;
    white-space: nowrap;
}

.dashbord-table .table thead tr {
    background: linear-gradient(135deg, #333537 35.49%, #161719 77.91%);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.dashbord-table .table td {
    color: #fff !important;
    font-weight: 700;
    text-decoration: none
}

.dash-acc-list li {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
    margin-bottom: 5px;
    padding-bottom: 5px;
}

/* calender */

/* multiform */
.multi-form ol {
    margin: 0 auto;
}

.go3842760039::before {
    content: "•";
    color: white;
    background-color: #027a4f;
}

.go433304200::before {
    content: "✓";
    color: white;
    background-color: #027a4f;
}

.go2150698616 {
    min-width: 16rem;
}

.go2335061104::before {
    content: ".";
    color: silver;
    background-color: white;
}

.multi-form button:nth-child(2) {
    float: right;
}

.multi-form button {
    display: inline-block;
    padding: 10px;
    border-radius: 8px;
    background-color: #027a4f !important;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    color: #fff;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 0.5px;
    width: max-content;
    margin-top: 20px;
}

.step-3 button:last-child {
    display: none;
    ;
}

/* Chrome, Safari, Edge, Opera */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    width: 0;
}

/* Firefox */
input[type="number"]::-moz-calendar-controls {
    display: none;
}

.step-one-pr-t {
    width: 70%;
    margin: 0 auto;
}

.breadcrumb-item a:hover {
    color: #027a4f !important;
}

/* recent news */
.recent-news-grp .recnt-bor {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.backtrap-window {
    background: #655a5a9e;
    z-index: 11111 !important;
}

.account-details img {
    width: 150px !important;
}

.account-details img:nth-child(2) {
    width: 153px !important;
}

.account-details img:nth-child(2) {
    display: none;
}

.account-details-button,
.secondary-option,
.account-details:hover img:nth-child(1) {
    display: none !important;
}

.account-details:hover .account-details-button,
.account-details:hover .secondary-option,
.account-details:hover img:nth-child(2) {
    display: block !important;
}

.account-details:hover .account-details-button {
    margin-bottom: 30px;
    margin-top: 30px;
}

.diamond {
    background-color: #027a4f;
    padding: 10px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    width: 30%;
    margin: 0 auto 15px auto;

}

.diamond::after,
.diamond::before {
    border-color: transparent #027a4f;
}

.diamond:before {
    left: -25px;
    border-width: 25px 25px 25px 0;
}

.diamond:after {
    right: -25px;
    border-width: 25px 0 25px 25px;
}

footer {
    z-index: 999;
    position: relative;
}

.h-500 {
    min-height: 500px;
}

.account-login-font {
    font-size: 14px;
    letter-spacing: 1px;
}

.no-record-position {
    position: absolute;
    top: 50%;
}

.popup-overlay {
    z-index: 9999;
    padding: 30px;
}

.popup {
    padding: 30px;
}

.certificate-div p {
    font-size: 15px;
    font-weight: 400;
}

.certificate-div {
    position: relative;
}

.certificate-div .download-btn {
    background-color: #027a4f;
    padding: 5px;
    width: auto;
    display: inline-block;
    border-radius: 5px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    cursor: pointer
}

.form-check-label {
    font-size: 14px;
    margin-bottom: 0 !important;
    font-weight: 500;
    margin-left: 8px;
    margin-top: 4px !important;
}

.kyc-form-group .form-check-input:checked {
    background-color: #027a4f;
    border-color: #027a4f;
}

.front_image__input,
.back_image__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #027a4f;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
    height: 16px;
    margin-right: 4px;
}