.info-holder {
    background-image: url('../assets/bg-sidebar-mobile.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 1rem clamp(5rem, 20vw, 10rem);
    grid-column: 1/2;

}

.info-container {
    display: flex;
    padding-right: 5px;
}

.info-container>*:where(:not(:last-child)) {
    margin-right: 15px;
}

.step-num {
    outline: 1px solid hsl(var(--white));
    color: hsl(var(--white));
    margin-top: .75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.step-num.current {
    background-color: hsl(2deg 59.06% 49.8%);
    color: #fff;
    outline: none;
}

.step-stat {
    display: none;
}

@media (min-width: 60rem) {
    .info-holder {
        background-image: url('../assets/bg-sidebar-desktop.svg');
        background-repeat: no-repeat;
        background-position: left;
        background-size: cover;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: start;
        padding-block: 0;
        width: 100%;
        border-radius: 20px;

    }

    .info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 2rem;
    }

    .info-container>*:where(:not(:last-child)) {
        margin-bottom: 15px;
    }

    .step {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 1.5rem;
    }

    .step-num {
        background-color: transparent;
        outline: 1px solid hsl(var(--white));
        color: hsl(var(--white));
        margin-top: .75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .step-stat {
        display: flex;
        flex-direction: column;
        line-height: 1rem;
        gap: .25rem;
        margin-bottom: 5px;
        align-self: end;
    }

    .step-stat>small {
        display: block;
        color: hsl(var(--Light-gray));
    }

    .step-stat>strong {
        display: block;
        color: hsl(var(--white));
        font-weight: var(--fwB);
    }
}
::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
.new-challange-logo{
    position: absolute;
    bottom: 0;
    width: 250px;
}
.newch-form-div input,
.newch-form-div select{
    background: rgb(51 53 55);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2x);
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: inline-block;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    height: 45px;;
    width: 100%;
}
.card-div {
    background: linear-gradient(135deg, #333537 35.49%, #161719 77.91%);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid #027a4f;
    padding: 10px;
    border-radius: 5px;
}
.newch-form-div label {
    color: rgb(51 53 55) !important;
    margin-bottom: 5px;
    font-size: 14px;
}