main {
    display: grid !important;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

form>*:first-child {
    align-self: center;
    margin-inline: 1rem;
}

.btn-container {
    background-color: hsl(var(--white));
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 10px !important;
    border-radius: 10px;
    margin-top: 10px;
}

.btn-container>.next-btn {
    color: hsl(var(--white));
    background-color: hsl(var(--Marine-blue));
    border-radius: 5px;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
}

.btn-container>.prev-btn {
    color: #fff;
    background-color: transparent;
    border: none;
    font-weight: var(--fwM);
    cursor: pointer;
    margin-bottom: 0 !important;
    padding: 5px 10px !important;
    border-radius: 10px;
    width:auto !important;
    margin-top: 0 !important;
}

@media(min-width: 60rem) {
    form {
        grid-column: 2/4 !important;
        padding-block: 0;
        padding-inline: 1rem;
    }

    .btn-container {
        background-color: hsl(var(--white));
        display: flex;
        justify-content: space-between;
        padding: 0rem 1rem 2rem 1rem;
        /* margin-bottom: 1rem; */
        align-items: center;
    }

    .btn-container>.next-btn,
    .btn-container>.prev-btn {
        border-radius: 10px;
        width: 125px;
        padding: .8rem .5rem;
    }

    .btn-container>.prev-btn {
        text-align: left;
    }
}